<template>
  <div class="g-section job-container">
    <div class="g-wrap job-header" v-if="EnterpriseId">
      <content-header :title-list="titleList" />
    </div>
    <div :class="['g-wrap','job-top',{'mt-0':!!EnterpriseId}]">
      <div class="flex" v-if="EnterpriseId">
        <!-- <div class="company-name">{{EnterpriseName}}</div> -->
        <div class="tag-search" v-if="!showPushSchool">
          <span style="margin-right:10px;">岗位类型:</span>
          <el-select v-model="JobSourceType" placeholder="请选择岗位类型" @change="loadData(true)">
            <el-option v-for="item in jobSourceList" :key="item.Value" :label="item.Text" :value="item.Value" />
          </el-select>
        </div>
      </div>
      <div class="flex" v-else>
        <el-input class="keyword-search" placeholder="请输入关键字查询" suffix-icon="el-icon-search" v-model="keyword" @input="loadData(true)" />
        <el-checkbox v-if="false" v-model="OnlyMySelf" @change="loadData(true)" border style="display: flex;align-items: center;margin-left:20px" label="只看自己新建的岗位" name="type" />
      </div>
      <el-button type="primary" size="small" icon="el-icon-circle-plus-outline" @click="goCreate">发布新职位</el-button>
    </div>
    <div class="g-wrap job-bottom" v-loading="loading">
      <list text="暂无岗位信息" :dataList="dataList" :totalCount="totalCount" :loading="loading" @loadMore="loadData">
        <div v-for="(item, i) in dataList" :class="['job-item',{'job-pushed':item.IsPushedSchool}]" :key="item.Id">
          <div :class="['item-top', item.Status == 1 ? 'opacity' : '']">
            <div class="item-left">
              <span class="mb-10 job-name">{{ item.Name }}</span>
              <span class="fs-14 color-light">
                {{ item.Department }} | {{ item.SalaryMin }}k-{{item.SalaryMax}}K | {{ item.ExperienceDescription }} | {{ item.EducationDescription }}
                | {{ item.WorkPlace }}
              </span>
            </div>
            <div class="item-right">
              <div class="right-status">
                <span class="dotted" :style="{ background: getStatus(item.Status).color }"></span>
                <span :style="{ color: getStatus(item.Status).textColor }">{{getStatus(item.Status).text}}</span>
              </div>
              <div class="color-base right-btn" @click="goResumeDelivery(item.Id,deliveryStatus,item.Name)">
                <span>
                  <span>{{ item.ResumeCount }}</span>
                  <span class="fs-12">人投递</span>
                </span>
                <span class="el-icon-arrow-right ml-12 fs-12 color-gray al"></span>
              </div>
              <div class="color-base right-btn" @click="goResumeDelivery(item.Id,candidateStatus,item.Name)">
                <span>
                  <span>{{ item.CandidateCount }}</span>
                  <span class="fs-12">人候选</span>
                </span>
                <span class="el-icon-arrow-right ml-12 fs-12 color-gray al"></span>
              </div>
            </div>
          </div>
          <div class="item-bottom">
            <div class="fs-14">
              <span class="color-light">发布时间：</span>
              <span class="color-light">{{dateTimeFormat(item.PublishTime)}}</span>
              <el-button style="margin-left:50px;" v-if="showPushStudent" type="text" icon="el-icon-s-promotion" size="small" class="color-primary" @click="goPushStudent(item)">
                推送学生（{{item.RecruitJobPushStudentList.length}}）
              </el-button>
            </div>
            <div class="btns" v-if="!item.IsPushedSchool">
              <template v-if="getStatus(item.Status).isOnline">
                <!-- <el-button v-if="showPushSchool" type="text" icon="el-icon-s-promotion" size="small" class="color-primary" @click="goPushSchool(item)">推送院校（{{item.RecruitJobPushSchoolList.length}}）</el-button> -->
                <el-button type="text" icon="el-icon-refresh" size="small" class="color-primary" @click="refresh(item.Id)">刷新</el-button>
              </template>
              <template v-else>
                <el-button type="text" icon="el-icon-edit" size="small" class="color-primary" @click="goEdit(item.Id)">编辑</el-button>
                <el-button v-if="item.Status===1" type="text" icon="el-icon-delete" size="small" class="color-primary" @click="deleteJob(item.Id, i)">
                  删除</el-button>
              </template>
              <el-button type="text" icon="el-icon-search" size="small" class="color-primary" @click="goDetail(item.Id)">查看</el-button>
              <el-button type="text" icon="el-icon-sort" size="small" class="color-primary" @click="toggleStatus(item.Id, item.Status)">
                {{ onlineOrOfflineText(item.Status) }}
              </el-button>
            </div>
            <div class="btns" v-else>
              <!-- <el-button type="text" size="small">该岗位为企业推送</el-button> -->
              <el-button type="text" icon="el-icon-search" size="small" class="color-primary" @click="goDetail(item.Id)">查看</el-button>
            </div>
          </div>
        </div>
      </list>
      <!-- <div class="loading">
        <div v-if="loading">加载中...</div>
        <div v-else-if="noMore">—— 没有更多了 ——</div>
        <el-button v-else @click="loadData(false)" type="text">加载更多</el-button>
      </div> -->
    </div>

    <el-dialog title="推送岗位需求至合作院校" :visible.sync="schoolDialogVisible" width="622px" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-transfer v-loading="submitLoading" filterable :titles="['未推送院校', '已推送院校']" filter-placeholder="请输入名称搜索" v-model="recruitJobPushSchoolList" :data="cooperationList" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushSchool" :loading="submitLoading">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="推送岗位至学生" :visible.sync="studentDialogVisible" width="622px" :before-close="handleClose" :close-on-click-modal="false" :close-on-press-escape="false">
      <el-tree :data="studentTree" show-checkbox empty-text="暂未添加学生或没有权限" default-expand-all :default-checked-keys="recruitJobPushStudentIdList" node-key="Id" ref="studentTree" highlight-current
        :props="studentTreeProps" />
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="pushStudent" :loading="submitLoading">确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import { getPageList, refresh, toggleStatus, deleteJob } from "@/api/job";
import { dateTimeFilter } from "@/filters";
import { mapGetters } from "vuex";
import { RecruitJobSourceTypeEnum, ResumeDeliveryStatusEnum, StaffTypeEnum } from "@/utils/constant";
import { getCooperationSchoolList } from '@/api/schoolenterprise';
import { edit } from '@/api/recruitjobpushschool';
import { getInfo } from '@/api/enterprise/enterpriseInfo'
import { edit as pushJobToStudent, getStudentTree } from '@/api/recruitjobpushstudent';
import ContentHeader from "@/components/Common/ContentHeader";
import List from "@/components/Common/List";
export default {
  components: { ContentHeader, List },
  data() {
    return {
      EnterpriseId: '',
      EnterpriseName: '',
      dataList: [],
      keyword: "",
      loading: false,
      pageIndex: 0,
      pageSize: 5,
      totalCount: 0,
      OnlyMySelf: true,
      schoolDialogVisible: false,
      recruitJobId: undefined,
      recruitJobPushSchoolList: [],
      cooperationList: [],
      submitLoading: false,
      JobSourceType: undefined,
      jobSourceList: [
        {
          Text: '全部',
          Value: undefined
        },
        {
          Text: '学校发布的',
          Value: RecruitJobSourceTypeEnum.SchoolPublish
        },
        {
          Text: '企业推送的',
          Value: RecruitJobSourceTypeEnum.EnterprisePush
        }
      ],
      studentDialogVisible: false,
      studentTreeProps: {
        children: 'Children',
        label: 'Name',
        disabled: function (data, node) {
          return false;
        }
      },
      studentTree: [],
      recruitJobPushStudentIdList: [],
      deliveryStatus: ResumeDeliveryStatusEnum.Delivery,
      candidateStatus: ResumeDeliveryStatusEnum.Candidate
    };
  },
  computed: {
    ...mapGetters(["employee"]),
    dateTimeFormat() {
      return data => dateTimeFilter(data);
    },
    noMore() {
      return this.loading == false && this.dataList.length == this.totalCount;
    },
    UnitType() {
      const { matched } = this.$route;
      if (matched.some(({ path }) => path.includes("enterprise"))) {
        return StaffTypeEnum.Enterprise;
      } else if (matched.some(({ path }) => path.includes("school"))) {
        return StaffTypeEnum.School;
      }
      return undefined;
    },
    UnitId() {
      const { Employer } =
        this.employee.find(({ StaffType }) => StaffType == this.UnitType) || {};
      return Employer && Employer.Id;
    },

    routeBase() {
      //todo:暂时这样，后续需要仔细判断
      return this.UnitType === StaffTypeEnum.Enterprise
        ? "enterprise"
        : "school";
    },
    showPushSchool() {
      return this.UnitType === StaffTypeEnum.Enterprise
    },
    showPushStudent() {
      return this.UnitType === StaffTypeEnum.School
    },
    titleList({ EnterpriseName }) {
      return [
        {
          text: "岗位管理",
          isCur: false,
          link: ""
        },
        {
          text: EnterpriseName,
          isCur: true,
          link: ""
        }
      ];
    }
  },
  watch: {
    '$route.query.e': {
      handler(val, old) {
        console.log('object :>> ', old, val);
        if (val === undefined && old) {
          this.EnterpriseId = '';
          this.EnterpriseName = '';
          this.loadData(true);
        } else if (val && old === undefined) {
          this.EnterpriseId = val;
          getInfo(val).then(({ Result: { EnterpriseName } = {} } = {}) => {
            this.EnterpriseName = EnterpriseName;
          });
          this.loadData(true);
        } else {
          this.loadData(true);
        }
      },
      immediate: true
    }
  },
  created() {
  },
  methods: {
    onlineOrOfflineText(status) {
      return status ? "上线" : "下线";
    },
    loadData(isInit) {
      this.loading = true;
      this.pageIndex = isInit ? 1 : (this.pageIndex + 1);
      var queryData = {
        KeyWord: this.keyword,
        PageSize: this.pageSize,
        PageIndex: this.pageIndex,
        OnlyMySelf: this.OnlyMySelf,
        UnitType: this.UnitType,
        UnitId: this.UnitId,
        EnterpriseId: this.EnterpriseId,
        JobSourceType: this.JobSourceType
      };
      getPageList(queryData).then(({ Result: { Data, TotalCount } }) => {
        this.dataList = isInit ? Data : this.dataList.concat(Data);
        this.totalCount = TotalCount;
        this.loading = false;
      });
    },
    refresh(id) {
      refresh(id).then(_ => {
        this.$message.success("刷新成功");
      });
      // this.$confirm("每个岗位每天只有三次刷新机会，确认刷新？", "温馨提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "warning"
      // })
      //   .then(() => {
      //     refresh(id).then(_ => {
      //       this.$message.success("刷新成功");
      //     });
      //   })
      //   .catch(_ => { });
    },
    toggleStatus(id, status) {
      const msg = `确认${this.onlineOrOfflineText(status)}？`;
      this.$confirm(msg, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          toggleStatus(id).then(_ => {
            const newList = this.dataList.map(({ Id, Status, ...other }) => {
              if (Id === id) {
                return { Id, Status: Status === 0 ? 1 : 0, ...other };
              } else {
                return { Id, Status, ...other };
              }
            });
            this.dataList = newList;
            this.$message.success('操作成功');
          });
        })
        .catch(_ => { });
    },
    deleteJob(id, index) {
      this.$confirm("删除后无法恢复，确认删除？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          deleteJob(id).then(_ => {
            this.dataList.splice(index, 1);
            if (this.totalCount > 0) {
              this.totalCount--;
            }
            this.$message.success('删除成功');
          });
        })
        .catch(_ => { });
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return {
            text: "已上线",
            color: "#598DF3",
            textColor: "#333333",
            isOnline: true
          };
        case 1:
          return {
            text: "已下线",
            color: "#CCCCCC",
            textColor: "#333333",
            isOnline: false
          };
        default:
          return {
            text: "草稿",
            color: "#CCCCCC",
            textColor: "#333333",
            isOnline: false
          };
      }
    },
    goCreate() {
      this.$router.push(`/${this.routeBase}/job/create?e=${this.EnterpriseId || ''}`);
    },
    goEdit(id) {
      this.$router.push(`/${this.routeBase}/job/edit?id=${id}&e=${this.EnterpriseId || ''}`);
    },
    goDetail(id) {
      this.$router.push(`/${this.routeBase}/job/detail?id=${id}&e=${this.EnterpriseId || ''}`);
    },
    goResumeDelivery(jobId, status, jobName) {
      // this.$router.push(`/${this.routeBase}/job/seeker?id=${jobId}&n=${jobName}&s=${status}`);
      if (this.UnitType === StaffTypeEnum.Enterprise) {
        this.$router.push({ name: `JobSeekerEnterprise`, params: { jobId,status,jobName } }); // 只能用 name
      }else if (this.UnitType === StaffTypeEnum.School) {
        this.$router.push({ name: `JobSeekerSchool`, params: { jobId,status,jobName } }); // 只能用 name
      }
    },
    goPushSchool({ Id, RecruitJobPushSchoolList = [] } = {}) {
      this.recruitJobId = Id;
      this.submitLoading = true;
      this.schoolDialogVisible = true;
      this.recruitJobPushSchoolList = RecruitJobPushSchoolList.map(item => item.SchoolId);
      getCooperationSchoolList(this.UnitId).then(({ Result }) => {
        this.cooperationList = Result.map(item => ({ label: item.Text, key: item.Value })).filter(({ key }) => !RecruitJobPushSchoolList.some(item => item.Id == key));
        this.submitLoading = false;
      });
    },
    pushSchool() {
      if (!this.cooperationList.length) {
        this.schoolDialogVisible = false;
        return;
      }
      this.submitLoading = true;
      // const data = this.recruitJobPushSchoolList.map(SchoolId => ({ RecruitJobId: this.recruitJobId, SchoolId }));
      const param = {
        RecruitJobId: this.recruitJobId,
        SchoolId: this.recruitJobPushSchoolList.join(',')
      };
      edit(this.recruitJobId, param).then(_ => {
        this.schoolDialogVisible = false;
        this.dataList.forEach(item => {
          if (item.Id === this.recruitJobId) {
            item.RecruitJobPushSchoolList = this.recruitJobPushSchoolList.map(SchoolId => ({ SchoolId }));
          }
        });
      }).catch(_ => {
        this.submitLoading = false;
      });
    },
    goPushStudent({ Id, RecruitJobPushStudentList = [] } = {}) {
      this.recruitJobId = Id;
      this.studentDialogVisible = true;
      this.recruitJobPushStudentIdList = RecruitJobPushStudentList.map(item => item.StudentId);

      getStudentTree(this.UnitId).then(({ Result }) => {
        this.studentTree = Result;
        this.submitLoading = false;
      })
    },
    pushStudent() {
      var checkedNodes = this.$refs['studentTree'].getCheckedNodes();
      //todo:需要修改level，考虑其他方法判断是否是学生
      const studentIdList = checkedNodes.filter(item => item.Level === 4).map(item => item.Id);
      if (!this.studentTree.length) {
        this.studentDialogVisible = false;
        return;
      }
      const param = {
        RecruitJobId: this.recruitJobId,
        StudentId: studentIdList.join(',')
      };
      this.submitLoading = true;
      pushJobToStudent(this.recruitJobId, param).then(() => {
        this.studentDialogVisible = false;
        this.dataList.forEach(item => {
          if (item.Id === this.recruitJobId) {
            item.RecruitJobPushStudentList = studentIdList.map(StudentId => ({ StudentId }));
          }
        });
      }).catch(_ => {
        this.submitLoading = false;
      });
    },
    handleClose() {
      this.$confirm('确认关闭？').then(_ => {
        this.schoolDialogVisible = false;
        this.studentDialogVisible = false;
      }).catch(_ => { });
    }
  }
};
</script>
<style lang="scss" scoped>
.job-container {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background: #f5f5f5;
}
.flex {
  line-height: 40px;
}
.g-wrap {
  background: #fff;
  // padding: 24px 16px;
}
.g-wrap.mt-0 {
  margin-top: 0;
}
.g-wrap.job-header {
  background: #f5f5f5;
}
.g-wrap.job-top {
  padding: 24px 24px 16px;
  border-bottom: 1px solid #eeeeee;
  // flex-direction: column;
}
.company-name {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
}
.query {
  display: flex;
  justify-content: space-between;
}
.g-wrap.job-bottom {
  padding: 16px 24px;
  margin-bottom: 20px;
}

.keyword-search {
  width: 200px;
}
.tag-search {
  // margin-left: 20px;
}

.job-top {
  justify-content: space-between;
  margin-top: 20px;
  border-radius: 8px 8px 0px 0px;
}
.job-bottom {
  display: flex;
  flex-direction: column;

  border-radius: 0px 0px 8px 8px;
}

.btn-search {
  margin-left: 10px;
}

.job-item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  margin-bottom: 16px;
  border: 1px solid #e2e4e8;
  border-radius: 6px;

  &:hover {
    box-shadow: 0px 2px 6px 0px #e7e7e7;
  }
  &.job-pushed {
    border-left: 5px solid #598DF3;
  }

  .opacity {
    opacity: 0.5;
  }
  .item-top {
    padding: 16px;
    display: flex;
    justify-content: space-between;

    .item-left {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;

      .job-name {
        font-size: 18px;
        font-weight: 600;
        color: #333333;
      }
    }

    .item-right {
      display: flex;
      align-items: center;
      .right-status {
        margin-right: 50px;
        .dotted {
          display: inline-block;
          width: 8px;
          height: 8px;
          margin-right: 8px;
          border-radius: 50%;
        }
      }
      .right-btn {
        cursor: pointer;
        margin-left: 20px;
        display: flex;
        align-items: center;
        &:hover {
          color: #598DF3;
        }
        .al {
          align-self: flex-end;
          margin-bottom: 2px;
        }
      }
    }
  }

  .item-bottom {
    display: flex;
    border-top: 1px solid #e7e8ea;
    background: #eeeeee;
    height: 24px;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;
    border-radius: 0 0 6px 6px;
  }

  .el-button {
    padding: 0px;
  }
}

.loading {
  text-align: center;
}
</style>
