import request from '@/utils/request'
const controller = '/RecruitJobPushStudent'

const edit = (jobId, data) =>
  request({
    url: `${controller}/Update?jobId=${jobId}`,
    method: "post",
    data
  });
const getStudentTree = schoolId =>
  request({
    url: `${controller}/GetStudentTree`,
    method: "get",
    params: {
      schoolId
    }
  });

export {
  edit,
  getStudentTree
}